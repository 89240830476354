import { useEffect, useState, useContext } from "react";
import PageHeader from "components/page-header";
import Table from "components/table";
import { ApiConsumer } from "api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import { useTextFormatHook } from "hooks/text-formatter";
import { usePermissionsHook } from "hooks/permissions";
import { ActiveKpaContext } from "contexts/active-kpa";
import { ActiveTermContext } from "contexts/active-term";
import * as Sentry from "@sentry/react";
import Alert from "components/alert";
import { Bar } from "components/charts/bar";
import { Doughnut } from "components/charts/doughnut";
import { StackedLine } from "components/charts/stacked";
import { HorizontalBar } from "../components/charts/hbar";
import { APP_NAME } from "config";

export default function Dashboard() {
    let { normaliseTableData } = useTextFormatHook();
    const [kpas, setKpa] = useState([]);
    const [loaded, setLoaded] = useState(false);
    let { setActiveKpa } = useContext(ActiveKpaContext);

    const tableHeaders = [
        {
            key: "name",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "total_projects",
            value: "Total Projects",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "total_tasks",
            value: "Total Tasks",
            showOnTable: true,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.SDBIP_KPIS_BY_KPA}/${row.id}/${row.term.id}/2024`}
                    onClick={() => setActiveKpa(row)}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.KPA)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                console.log(res.data);
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setKpa(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setKpa({});
            setLoaded(false);
        };
    }, []);

    return (
        <>
            <PageHeader pageHeaderName={`DASHBOARD - ` + APP_NAME} />
            {loaded &&
                (kpas.length ? (
                    <>
                        <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                            <h3 className="mb-2">Overview</h3>
                            <div className="flex space-x-3">
                                <Doughnut
                                    url={API_ROUTES.SECTION.GRAPHS.TYPE}
                                    title={"Programmes by Type"}
                                />

                                <Doughnut
                                    url={
                                        API_ROUTES.SECTION.GRAPHS
                                            .PROJECT_ACTIVE_INACTIVE
                                    }
                                    title={"Active vs Complete Programmes"}
                                />

                                <StackedLine
                                    url={
                                        API_ROUTES.SECTION.GRAPHS
                                            .SECTION_BY_PROGRESS
                                    }
                                    title={"Section/Group by Progress"}
                                />
                            </div>
                        </div>

                        <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                            <h3 className="mb-2">Projects Overview</h3>
                            <div className="flex space-x-3">
                                <StackedLine
                                    url={
                                        API_ROUTES.SECTION.GRAPHS
                                            .PROJECT_BY_PROGRESS
                                    }
                                    title={"Projects by Progress"}
                                />

                                <HorizontalBar
                                    url={
                                        API_ROUTES.SECTION.GRAPHS
                                            .PROJECT_BY_TARGET
                                    }
                                    title={"Projects by Target vs Progress"}
                                    name={"type"}
                                    height={"250"}
                                />
                            </div>
                        </div>

                        {/*<div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">*/}
                        {/*    <div className="flex space-x-3">*/}
                        {/*        <Doughnut*/}
                        {/*            url={*/}
                        {/*                API_ROUTES.SECTION.GRAPHS*/}
                        {/*                    .PROJECT_BY_DEPARTMENT*/}
                        {/*            }*/}
                        {/*            title={"Projects by Partner Organisations"}*/}
                        {/*        />*/}

                        {/*        <Doughnut*/}
                        {/*            url={*/}
                        {/*                API_ROUTES.SECTION.GRAPHS*/}
                        {/*                    .PROJECT_BY_ACTIVITY*/}
                        {/*            }*/}
                        {/*            title={"Project by Activities"}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<Bar*/}
                        {/*    url={API_ROUTES.CONTENT.KPA}*/}
                        {/*    title={"KPA Summary"}*/}
                        {/*/>*/}
                        <h2 className="flex-1 flex mx-full px-4 sm:px-6 lg:px-8 mt-8 text-lg leading-6 font-medium text-gray-900 text-left">
                            2024 Key Performance Areas ({kpas?.length})
                        </h2>
                        <Table
                            tableTitle={`Projects by KPA`}
                            tableHeaders={tableHeaders}
                            tableData={kpas}
                            editable={false}
                        />
                    </>
                ) : (
                    <div className="mx-full px-8 my-4 text-center">
                        <Alert
                            type={`danger`}
                            message={`There are no KPAs added.`}
                        />
                    </div>
                ))}
        </>
    );
}
