/*
    SOURCES:
    https://www.robinwieruch.de/react-router-private-routes/
    https://www.robinwieruch.de/react-router-descendant-routes/
*/
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { APP_ID } from "config";
import PrivateRoute from "routes/private-route";
import { AppLoaderContext } from "contexts/app-loader";
import { AuthenticationContext } from "contexts/authentication";
import { ActiveKpaContext } from "contexts/active-kpa";
import { ActiveTermContext } from "contexts/active-term";
import NotFound from "pages/404";
import Login from "pages/login";
import ForgotPassword from "pages/forgot-password";
import Dashboard from "pages/dashboard";
import SectionRoutes from "routes/section-routes";
import ProjectRoutes from "routes/project-routes";
import TaskRoutes from "routes/task-routes";
import ReportRoutes from "routes/report-routes";
import AdminRoutes from "routes/admin-routes";
import { PORTAL } from "routes/portal";
import Kpis from "pages/kpis";
import Kpas from "pages/kpas";
import Terms from "pages/terms";
/* import { NotificationContext } from 'contexts/NotificationContext'
import NotificationsWrapper from 'components/notification-wrapper'
import Notifications from 'components/notifications' */

export default function App() {
    const [loading, setLoading] = useState(false);
    const [showNotifications, setNotification] = useState({
        panel: false,
        popup: false,
    });
    const [currentUser, setCurrentUser] = useState({});
    const [activeKpa, setActiveKpa] = useState({});
    const [activeTerm, setActiveTerm] = useState({});

    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    if (
        Object.keys(currentUser).length === 0 &&
        loggedInUser !== null &&
        loggedInUser !== undefined
    ) {
        setCurrentUser(loggedInUser);
    }
    return (
        <>
            <AppLoaderContext.Provider value={{ loading, setLoading }}>
                <AuthenticationContext.Provider
                    value={{ currentUser, setCurrentUser }}
                >
                    <ActiveTermContext.Provider
                        value={{ activeTerm, setActiveTerm }}
                    >
                        <ActiveKpaContext.Provider
                            value={{ activeKpa, setActiveKpa }}
                        >
                            {/* <NotificationContext.Provider
              value={{ showNotifications, setNotification }}
            >
              <NotificationsWrapper />
              <Notifications /> */}
                            <BrowserRouter>
                                <Routes>
                                    {/* PUBLIC ROUTES */}
                                    <Route
                                        path={PORTAL.LOGIN}
                                        element={<Login />}
                                    />
                                    <Route
                                        path={PORTAL.FORGOTPASSWORD}
                                        element={<ForgotPassword />}
                                    />

                                    {/* PRIVATE ROUTES */}

                                    <Route
                                        path={PORTAL.DASHBOARD}
                                        element={<PrivateRoute />}
                                    >
                                        <Route index element={<Dashboard />} />
                                        <Route
                                            path={PORTAL.TERMS}
                                            element={<Terms />}
                                        />

                                        <Route
                                            path={
                                                PORTAL.KPAS_BY_TERM +
                                                `/:term_id`
                                            }
                                            element={<Kpas />}
                                        />

                                        <Route
                                            path={
                                                `/:type` +
                                                PORTAL.KPIS_BY_KPA +
                                                `/:kpa_id/:term_id/:year`
                                            }
                                            element={<Kpis />}
                                        />

                                        <Route
                                            path={PORTAL.SECTIONS + "/*"}
                                            element={<SectionRoutes />}
                                        />
                                        <Route
                                            path={PORTAL.PROJECTS + "/*"}
                                            element={<ProjectRoutes />}
                                        />
                                        <Route
                                            path={PORTAL.TASKS + "/*"}
                                            element={<TaskRoutes />}
                                        />
                                        <Route
                                            path={PORTAL.REPORTS + "/*"}
                                            element={<ReportRoutes />}
                                        />
                                        <Route
                                            path={PORTAL.ADMIN + "/*"}
                                            element={<AdminRoutes />}
                                        />
                                    </Route>

                                    {/* 404 ROUTES */}

                                    <Route
                                        path={PORTAL.NOT_FOUND}
                                        element={<NotFound />}
                                    />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </BrowserRouter>
                            {/* </NotificationContext.Provider> */}
                        </ActiveKpaContext.Provider>
                    </ActiveTermContext.Provider>
                </AuthenticationContext.Provider>
            </AppLoaderContext.Provider>
        </>
    );
}
