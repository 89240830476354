import { useState, useEffect, useContext } from "react";
import { PORTAL } from "routes/portal";
import { PencilIcon } from "@heroicons/react/24/outline";
import { isAfter } from "date-fns";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useAuthentication } from "hooks/authentication";
import { PERMISSIONS } from "constants/permissions";
import { GLOBAL } from "constants/global";
import { API_ROUTES } from "routes/api";
import { format } from "date-fns";
import Status from "components/progress-slider/status";
import Progress from "components/progress-slider/progress";
import Bar from "components/progress-slider/bar";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import ViewUserDetails from "components/view-user-details";
import DownloadAsPdf from "components/share/download-pdf";
import Delete from "components/delete";
import AddEditTIDInformation from "components/forms/add-edit-tid-information";
import AddEditComments from "components/forms/add-edit-comments";
import AddEditPOE from "components/forms/add-edit-poe";
import AddEditChallenges from "components/forms/add-edit-challenges";
import AddEditRisk from "./forms/add-edit-risk";

export default function CardWide({
    row,
    editable,
    setEditableRow,
    reloadProjectData,
    tableHeaders,
}) {
    let { hasPermission } = useAuthentication();
    const [showMoreText, setShowMoreText] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [rowUpdated, setRowUpdated] = useState(false);
    const [openAddEditChallenges, setOpenAddEditChallenges] = useState(false);
    const [editableChallenges, setEditableChallenges] = useState({});
    const [openAddEditTIDInformation, setOpenAddEditTIDInformation] =
        useState(false);
    const [editableTIDInformation, setEditableTIDInformation] = useState({});
    const [openAddEditComments, setOpenAddEditComments] = useState(false);
    const [editableComments, setEditableComments] = useState({});
    const [openAddEditPOE, setOpenAddEditPOE] = useState(false);
    const [editablePOE, setEditablePOE] = useState({});
    const [editableRisk, setEditableRisk] = useState({});
    const [openAddEditRisk, setOpenAddEditRisk] = useState(false);
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    useEffect(() => {
        return () => {
            setShowMoreText(false);
        };
    }, []);

    const onCloseAddEditTIDInformation = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableTIDInformation({});
            if (rowUpdated) reloadProjectData();
        }
        setOpenAddEditTIDInformation(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableTIDInformation).length === 0
            ? setOpenAddEditTIDInformation(false)
            : setOpenAddEditTIDInformation(true);
    }, [editableTIDInformation]);

    const onCloseAddEditComments = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableComments({});
        }
        setOpenAddEditComments(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableComments).length === 0
            ? setOpenAddEditComments(false)
            : setOpenAddEditComments(true);
    }, [editableComments]);

    const onCloseAddEditPOE = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditablePOE({});
        }
        setOpenAddEditPOE(modalStatus);
    };

    const onCloseAddEditRisk = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRisk({});
        }
        setOpenAddEditRisk(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editablePOE).length === 0
            ? setOpenAddEditPOE(false)
            : setOpenAddEditPOE(true);
    }, [editablePOE]);

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRisk).length === 0
            ? setOpenAddEditRisk(false)
            : setOpenAddEditRisk(true);
    }, [editableRisk]);

    const onCloseAddEditChallenges = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableChallenges({});
        }
        setOpenAddEditChallenges(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableChallenges).length === 0
            ? setOpenAddEditChallenges(false)
            : setOpenAddEditChallenges(true);
    }, [editableChallenges]);

    return (
        <>
            <Modal
                modalHeaderTag={`PROJECT: ` + row?.name}
                modalHeader={`Project Challenges`}
                modalContent={
                    <AddEditChallenges
                        type="project"
                        obj={row}
                        route={API_ROUTES.CHALLENGES.PROJECTS}
                    />
                }
                open={openAddEditChallenges}
                setOpen={onCloseAddEditChallenges}
            />
            <Modal
                modalHeaderTag={`PROJECT: ` + row?.name}
                modalHeader={`Project Comments`}
                modalContent={
                    <AddEditComments
                        type="project"
                        obj={row}
                        route={API_ROUTES.COMMENTS.PROJECTS}
                    />
                }
                open={openAddEditComments}
                setOpen={onCloseAddEditComments}
            />

            <Modal
                modalHeaderTag={`PROJECT: ` + row?.name}
                modalHeader={`Project POE`}
                modalContent={
                    <AddEditPOE
                        type="project"
                        obj={row}
                        route={API_ROUTES.ATTACHMENTS.PROJECTS}
                    />
                }
                open={openAddEditPOE}
                setOpen={onCloseAddEditPOE}
            />

            <Modal
                modalHeaderTag={`PROJECT: ` + row?.name}
                modalHeader={`Project TID Information`}
                modalContent={
                    <AddEditTIDInformation
                        type="project"
                        obj={row}
                        route={API_ROUTES.TID.PROJECTS}
                    />
                }
                open={openAddEditTIDInformation}
                setOpen={onCloseAddEditTIDInformation}
            />
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${row.created_by.first_name} ${row.created_by.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <Modal
                modalHeaderTag={`PROJECT: ${row?.name}`}
                modalHeader={`Project Risk`}
                modalContent={
                    <AddEditRisk
                        type="project"
                        editableRow={row}
                        setRowUpdated={setEditableRisk}
                        setOpen={setOpenAddEditRisk}
                    />
                }
                open={openAddEditRisk}
                setOpen={onCloseAddEditRisk}
            />

            <div
                key={row.id}
                className="mx-full px-4 sm:px-6 lg:px-8 my-5 text-sm"
            >
                <div className="bg-white rounded-md shadow-md">
                    <div className="w-full p-5">
                        <div className="flex space-x-3 items-center">
                            <div className="min-w-0 flex-1">
                                <span className="header-tag-green">
                                    {row.year}
                                </span>
                                <span className="header-tag-green">
                                    SECTION: {row.programme.name}
                                </span>
                            </div>
                            <div className="flex-shrink-0 self-center flex">
                                <button
                                    type="button"
                                    className="button m-auto w-auto ml-1"
                                    onClick={() => {
                                        setEditableTIDInformation(row);
                                        setOpenAddEditTIDInformation(true);
                                    }}
                                >
                                    TID Information
                                </button>

                                <button
                                    type="button"
                                    className="button m-auto w-auto ml-1"
                                    onClick={() => {
                                        setEditablePOE(row);
                                        setOpenAddEditPOE(true);
                                    }}
                                >
                                    Project POE
                                </button>

                                <button
                                    type="button"
                                    className="button m-auto w-auto ml-1 bg-amber-500 hover:bg-amber-600"
                                    onClick={() => {
                                        setEditableRisk(row);
                                        setOpenAddEditRisk(true);
                                    }}
                                >
                                    Project Risk
                                </button>

                                <button
                                    type="button"
                                    onClick={() => {
                                        setEditableChallenges(row);
                                        setOpenAddEditChallenges(true);
                                    }}
                                    className={`button-default ml-1`}
                                >
                                    Challenges
                                </button>

                                <button
                                    target="_blank"
                                    className={`button-default ml-1`}
                                    onClick={() => {
                                        setEditableComments(row);
                                        setOpenAddEditComments(true);
                                    }}
                                >
                                    Comments
                                </button>

                                <NavLink
                                    to={`${PORTAL.PROJECT}/${row.id}`}
                                    className={`button-default ml-1`}
                                >
                                    View Project
                                    <span className="button-icon m-0">
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="flex space-x-3">
                            <div className="min-w-0 flex-1">
                                <div className="flex space-x-3">
                                    <h2 className="whitespace-normal text-lg">
                                        {row.normalised.name}
                                        {editable &&
                                            !row.closed &&
                                            hasPermission(
                                                PERMISSIONS.PROJECTS
                                                    .CAN_EDIT_PROJECT
                                            ) && (
                                                <button
                                                    type="button"
                                                    className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                >
                                                    <PencilIcon
                                                        className="h-4 w-4 mr-1"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                            setEditableRow(row)
                                                        }
                                                    />
                                                </button>
                                            )}
                                    </h2>
                                </div>
                                <div>
                                    <p className="text-gray-400">
                                        {row.overview.length > 250 ? (
                                            <>
                                                {showMoreText
                                                    ? row.overview + ` `
                                                    : row.overview.substr(
                                                          0,
                                                          250
                                                      ) + ` ... `}
                                                <span
                                                    className="brand-link"
                                                    onClick={() => {
                                                        setShowMoreText(
                                                            !showMoreText
                                                        );
                                                    }}
                                                >
                                                    show{" "}
                                                    {showMoreText
                                                        ? `less`
                                                        : `more`}
                                                </span>
                                            </>
                                        ) : (
                                            row.overview
                                        )}
                                    </p>
                                </div>
                                <div className="pt-1">
                                    <strong>Project Date:</strong>{" "}
                                    {row.normalised.start_date +
                                        " - " +
                                        row.normalised.end_date}
                                    &nbsp;|&nbsp;
                                    <strong>Project Duration:</strong>{" "}
                                    {row.normalised.duration}
                                </div>
                                <div className="pt-1">
                                    <strong>Ward:</strong> {row.wardnum}
                                    &nbsp;|&nbsp;
                                    <strong>
                                        GPS Coordinates (Long):
                                    </strong>{" "}
                                    {row.longitude}
                                    &nbsp;|&nbsp;
                                    <strong>GPS Coordinates (Lat):</strong>{" "}
                                    {row.latitude}
                                </div>
                                <div className="pt-1">
                                    <strong>IDP Ref No:</strong>{" "}
                                    {row.normalised.idp_ref}
                                    &nbsp;|&nbsp;
                                    <strong>Draft Budget: </strong>
                                    {currencyFormatter
                                        .format(row.normalised.budget)
                                        .replace(/[ZA\s]/g, "")}
                                    &nbsp;|&nbsp;
                                    <strong>2nd Draft Budget: </strong>
                                    {currencyFormatter
                                        .format(row.normalised.second_budget)
                                        .replace(/[ZA\s]/g, "")}
                                    &nbsp;|&nbsp;
                                    <strong>Final Budget: </strong>
                                    {currencyFormatter
                                        .format(row.normalised.actual_budget)
                                        .replace(/[ZA\s]/g, "")}
                                    {row?.partners?.length ? (
                                        <>
                                            &nbsp;|&nbsp;
                                            <span>
                                                <strong>Funded By:</strong>{" "}
                                                {row.partners.map(
                                                    (partner, i) => (
                                                        <span
                                                            key={i}
                                                            className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-sm"
                                                        >
                                                            {partner.name}
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                        </>
                                    ) : null}
                                </div>

                                <div className="pt-1">
                                    <strong>Department / Division:</strong>{" "}
                                    {row.departments.map((department, i) => (
                                        <span key={i}>
                                            {department.name}
                                            {i + 1 !==
                                                row?.normalised?.departments
                                                    ?.length && ", "}
                                        </span>
                                    ))}
                                </div>
                                <div className="pt-1">
                                    <ViewUserDetails row={row} />
                                </div>
                                <div className="pt-1">
                                    <strong>Project Deliverables:</strong>{" "}
                                    {row?.normalised?.deliverables.map(
                                        (deliverable, i) => {
                                            const [
                                                deliverable_date,
                                                deliverable_description,
                                            ] = deliverable;
                                            return (
                                                <span
                                                    key={i}
                                                    className="m-1 rounded-md bg-gray-100 py-1 pr-2"
                                                >
                                                    <span className="rounded-md bg-gray-200 mr-2 py-1 px-2">
                                                        {deliverable_date}
                                                    </span>
                                                    {deliverable_description}
                                                </span>
                                            );
                                        }
                                    )}
                                </div>
                                <div className="pt-2">
                                    <span className="inline-flex uppercase items-center px-2 py-0.5 rounded font-medium tag bg-green-800 text-white text-xs">
                                        Score Type:{" "}
                                        {row?.indicator?.indicator_umo?.name}
                                    </span>
                                </div>
                                {row?.indicator?.indicator_umo?.name.toUpperCase() ===
                                    `PERCENTAGE` ||
                                row?.indicator?.indicator_umo?.name.toUpperCase() ===
                                    `NUMBER` ? (
                                    <>
                                        <div className="pt-2">
                                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                <Bar
                                                    progressValue={
                                                        row.progress
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="pt-1">
                                            <div className="flex justify-between mb-1">
                                                <Status
                                                    progressValue={
                                                        row.progress
                                                    }
                                                />
                                                <Progress
                                                    progressValue={
                                                        row.progress
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 px-5 py-2 rounded-bl-lg rounded-br-lg bg-gray-100 flex items-center">
                        <div className="flex-1 min-w-0">
                            <span className="font-bold">Created:</span>{" "}
                            {format(new Date(row.created), "dd MMM yyyy")} by{" "}
                            <span
                                className="hover:underline cursor-pointer"
                                onClick={() => {
                                    setUserDetailsRow(row.created_by);
                                    setOpenUserDetails(true);
                                }}
                            >
                                {row.created_by.first_name +
                                    " " +
                                    row.created_by.last_name}
                            </span>{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {row.normalised.updated}
                        </div>
                        <div className="flex space-x-2">
                            <DownloadAsPdf
                                tableTitle={`Project: `}
                                tableHeaders={tableHeaders}
                                tableData={[row]}
                                tableDataResult={row.name}
                                printType={GLOBAL.PRINT.PAGE}
                                filename={`Project: ${row.name}`}
                            />
                            {hasPermission(
                                PERMISSIONS.PROJECTS.CAN_DELETE_PROJECT
                            ) && (
                                <Delete
                                    apiRoute={API_ROUTES.PROJECT.PROJECT_DETAIL}
                                    rowData={row}
                                    loadData={reloadProjectData}
                                    titleValue={"Project"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
