import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "hooks/form-validation";
import Alert from "components/alert";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { format, compareDesc } from "date-fns";
import { AuthenticationContext } from "contexts/authentication";
import Delete from "components/delete";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import { PaperClipIcon, PencilIcon } from "@heroicons/react/24/outline";
import { AppLoaderContext } from "contexts/app-loader";
import DownloadAsPdf from "components/share/download-pdf";
import { GLOBAL } from "constants/global";
import { useTextFormatHook } from "hooks/text-formatter";

export default function TIDInformation({ type, route, obj, quarter = null }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [tids, setTids] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { normaliseTableData } = useTextFormatHook();

    const formFields = [
        { name: "section_name", display: "section name" },
        { name: "section_description", display: "section description" },
    ];

    const validateAddTid = (isEdit) => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            let formData = formDetails;

            if (formDetails.report_required) {
                formData = new FormData();
                for (const key in formDetails) {
                    formData.append(key, formDetails[key]);
                }
                formData.append("report_required", formDetails.report_required);
            }

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.TID.TID_DETAIL(editableRow.id),
                    formData
                )
                    .then((res) => {
                        loadTids();
                        setFormSubmitted(true);
                        setEditableRow({});

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`section_name`]: "",
                        }));
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`section_description`]: "",
                        }));
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`report_required`]: null,
                        }));
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.TID.TID, formData)
                    .then((res) => {
                        loadTids();
                        setFormSubmitted(true);

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`section_name`]: "",
                        }));
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`section_description`]: "",
                        }));
                        setFormDetails((prevState) => ({
                            ...prevState,
                            [`report_required`]: null,
                        }));
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadTids = () => {
        ApiConsumer.get(route(obj.id))
            .then((res) => {
                /* res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                ); */
                setTids(res.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    useEffect(() => {
        loadTids();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: obj.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`section_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`section_description`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`report_required`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`created_by`]: currentUser.id,
        }));

        return () => {
            setTids([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [obj.id, currentUser.id]);

    const TIDTableHeaders = [
        { key: "section_name", value: "Name", showOnTable: true, showOnPrint: true },
        {
            key: "section_description",
            value: "Description",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_required",
            value: "Report Required",
            showOnTable: true,
            showOnPrint: true,
        }];

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="">
                <div className="space-x-3 bg-gray-100 px-4 py-6 sm:px-6">
                    <form
                        className="space-y-1 relative"
                        onSubmit={(event) => {
                            event.preventDefault();
                            validateAddTid(Object.keys(editableRow).length);
                        }}
                    >
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="section_name"
                                >
                                    Section Name:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="section_name"
                                    name="section_name"
                                    type="text"
                                    autoComplete="section_name"
                                    className={`form-field ${
                                        invalidFormDetails.section_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`section_name`]:
                                                event.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.section_name
                                            ? formDetails.section_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="section_description"
                                >
                                    Section Description:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="section_description"
                                    name="section_description"
                                    autoComplete="section_description"
                                    className={`form-field ${
                                        invalidFormDetails.section_description
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`section_description`]:
                                                event.target.value,
                                        }))
                                    }
                                    onFocus={() => {
                                        setFormSubmitted(false);
                                    }}
                                    value={
                                        formDetails.section_description
                                            ? formDetails.section_description
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="report_required"
                                >
                                    Report required to be uploaded: (optional)
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="report_required"
                                    name="report_required"
                                    autoComplete="report_required"
                                    className={`form-field ${
                                        invalidFormDetails.report_required
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`report_required`]:
                                                event.target.files[0],
                                        }));
                                        setFormSubmitted(false);
                                    }}
                                    //value={formDetails.report_required}
                                    type="file"
                                />
                            </div>
                        </div>
                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-right">
                                {formError}
                            </div>
                        )}
                        {formSubmitted && (
                            <Alert
                                type={`success`}
                                message={`TID information has been successfully posted.`}
                            />
                        )}
                        <div className="mt-2 float-right">
                            <button type="submit" className="button">
                                Update TID Information
                            </button>
                        </div>
                        <div className="clear-both"></div>
                    </form>
                </div>

                <div
                    className={`${
                        type === "task" ? "px-4 py-6 sm:px-6" : "py-6"
                    }`}
                >
                    {tids.length ? <>
                        <div className="text-center mb-4"><DownloadAsPdf
                            tableTitle={`Project TID Information:`}
                            tableHeaders={TIDTableHeaders}
                            tableData={normaliseTableData(TIDTableHeaders, tids)}
                            tableDataResult={obj?.name}
                            printType={GLOBAL.PRINT.TABLE}
                            filename={`Project TID Information: ${obj?.name}`}
                            downloadIconText={"Download TID Information"}
                        />
                        </div>
                        <div className="clear-both"></div>
                        </>
                      : null
                    }

                    <ul>
                        {tids.length
                            ? tids.map((tid, i) => (
                                  <li key={i} className="border-b border-gray-100 pb-3 mb-3">
                                      <div className="flex space-x-3">
                                          <div className="flex-shrink-0">
                                              {tid.created_by.id ===
                                                  currentUser.id && (
                                                  <>
                                                      <Delete
                                                          apiRoute={
                                                              API_ROUTES.TID
                                                                  .TID_DETAIL
                                                          }
                                                          rowData={tid}
                                                          loadData={loadTids}
                                                          titleValue={
                                                              "TID Information"
                                                          }
                                                      />
                                                      <div className="clear-both"></div>
                                                      <button
                                                          type="button"
                                                          className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                      >
                                                          <PencilIcon
                                                              className="h-4 w-4 mr-1"
                                                              aria-hidden="true"
                                                              onClick={() => {
                                                                  setFormDetails(
                                                                      (
                                                                          prevState
                                                                      ) => ({
                                                                          ...prevState,
                                                                          [`section_name`]:
                                                                              tid.section_name,
                                                                      })
                                                                  );
                                                                  setFormDetails(
                                                                      (
                                                                          prevState
                                                                      ) => ({
                                                                          ...prevState,
                                                                          [`section_description`]:
                                                                              tid.section_description,
                                                                      })
                                                                  );
                                                                  setEditableRow(
                                                                      tid
                                                                  );
                                                                  setFormSubmitted(
                                                                      false
                                                                  );
                                                              }}
                                                          />
                                                      </button>
                                                  </>
                                              )}
                                          </div>
                                          <div>
                                              <div className="mt-1 text-sm ">
                                                  <span className="font-medium">
                                                      Section Name:{" "}
                                                      <span className="text-gray-700">
                                                          {tid.section_name}
                                                      </span>
                                                  </span>
                                              </div>
                                              <div className="mt-1 text-sm">
                                                  <span className="font-medium">
                                                      Section Description:{" "}
                                                      <span className="text-gray-700">
                                                          {
                                                              tid.section_description
                                                          }
                                                      </span>
                                                  </span>
                                              </div>
                                              {tid?.report_required && (
                                                  <div className="mt-1 text-sm">
                                                      <span className="font-medium">
                                                          Report Required:{" "}
                                                          <span className="text-sm">
                                                              <PaperClipIcon
                                                                  className="h-4 w-4 inline"
                                                                  aria-hidden="true"
                                                              />

                                                              <a
                                                                  href={
                                                                      tid?.report_required
                                                                  }
                                                                  target="_blank"
                                                                  className="brand-link truncate inline"
                                                              >
                                                                  View
                                                                  Attachment
                                                              </a>
                                                          </span>
                                                      </span>
                                                  </div>
                                              )}
                                              <div>
                                                  
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))
                            : "There is no TID information posted."}
                    </ul>
                </div>
            </div>
        </>
    );
}
